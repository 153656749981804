// import Ably from 'ably';
import * as bootstrap from "bootstrap";
import docReady from '../common/doc_ready';

const demo = async () => {
  let isShown = false;

  const btn = document.getElementById('demo-btn');
  if (btn) {
    btn.addEventListener('click', async () => {
      if (isShown) { return; }
      isShown = true;

      // @ts-ignore
      document.getElementById('demo').classList.remove('d-none');

      const emailUser = `mightyparse_demo+${Math.floor(Math.random() * 1e12)}`;
      const email = `${emailUser}@mightyparse.net`;

      const emailDom = document.getElementById('demo-email');
      // @ts-ignore
      emailDom.setAttribute('href', `mailto:${email}`);
      // @ts-ignore
      emailDom.textContent = email;

      // const ably = new Ably.Realtime('AV5mcQ.1VBBJg:z8HuBhRjMQFkPuEkPNYi6W5s4PyXiQVHwaoRgqUtYZc');
      // await ably.connection.once('connected');
      // const channel = ably.channels.get(email);

      // await channel.subscribe('email', (msg) => {
      //   const { data } = msg;
      //   console.log('Received a greeting message in realtime: ' + data);

      //   // @ts-ignore
      //   document.getElementById('demo-loading').classList.add('d-none');

      //   // @ts-ignore
      //   document.getElementById('demo-data').textContent = JSON.stringify(data, null, 2);
      // });
    });
  }
};

const image = () => {
  var imageModal = new bootstrap.Modal(document.getElementById('imageModal'));

  document.querySelectorAll('.img-expandable').forEach((image) => {
    image.addEventListener('click', (e) => {
      // @ts-ignore
      var imageSrc = e.target.src;
      var modalImage = document.getElementById('modalImage');
      // @ts-ignore
      modalImage.src = imageSrc;
      imageModal.show();
    });
  });
}

docReady(() => {
  // demo();
  image();
});
